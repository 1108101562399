import { render, staticRenderFns } from "./BookletChange.vue?vue&type=template&id=464c0e0a&"
import script from "./BookletChange.vue?vue&type=script&lang=js&"
export * from "./BookletChange.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCol, BRow, BFormCheckbox, BCard, BLink, BContainer} from 'bootstrap-vue'
    installComponents(component, {BButton, BCol, BRow, BFormCheckbox, BCard, BLink, BContainer})
    

export default component.exports