<template>
  <b-container class="p-3">
    <b-row class="my-3">
      <b-col sm="auto">
        <b-button
          variant="outline-info"
          :to="{ name: 'booklets-overview' }"
          class="mb-1"
        >
          <font-awesome-icon :icon="['fas', 'arrow-left']" /> Späť na prehľad
        </b-button>
      </b-col>
      <b-col sm="auto">
        <b-button
          variant="outline-info"
          class="mb-1"
          @click.prevent="reloadContent"
        >
          <font-awesome-icon :icon="['fas', 'sync']" /> Obnoviť obsah
        </b-button>
      </b-col>
      <b-col sm="auto">
        <b-button
          variant="outline-info"
          class="mb-1"
          target="_blank"
          :href="
            $root.backendAdminBaseURL + `booklets/booklet/${bookletId}/change/`
          "
        >
          <font-awesome-icon :icon="['fas', 'book-open']" /> Upraviť v adminovi
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <LoadingSpinner :is-loaded="isLoaded">
          <h2>{{ booklet.name }}</h2>
        </LoadingSpinner>
      </b-col>
      <b-col md="auto">
        <b-form-checkbox v-model="solutionsShown">
          Náhľad vzorákov
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <LoadingSpinner :is-loaded="isLoaded">
          <div v-for="block in booklet.blocks" :key="block.id" class="my-3">
            <b-card
              v-if="block.block_type === 'problem'"
              :title="`${block.problem.number}. ${block.problem.name}`"
            >
              <vue-markdown :source="block.problem.statement" />
              <b-card v-if="solutionsShown" title="Riešenie" class="bg-light">
                <vue-markdown :source="block.problem.solution" />
              </b-card>
              <template v-slot:footer>
                <small>
                  <a href="" @click.prevent="moveBlock(block, -1)"
                    ><font-awesome-icon :icon="['fas', 'arrow-up']"
                  /></a>
                  |
                  <a href="" @click.prevent="moveBlock(block, 1)"
                    ><font-awesome-icon :icon="['fas', 'arrow-down']"
                  /></a>
                  |
                  <b-link
                    :to="{
                      name: 'statement-change',
                      params: { problemId: block.problem.id },
                    }"
                    >Upraviť úlohu</b-link
                  >
                  |
                  <b-link
                    :to="{
                      name: 'solution-change',
                      params: { problemId: block.problem.id },
                    }"
                    >Upraviť vzorák</b-link
                  >
                  |
                  <a
                    target="_blank"
                    :href="
                      $root.backendAdminBaseURL +
                      `booklets/problemblock/${block.id}/change/`
                    "
                    >Vybrať inú úlohu</a
                  >
                  |
                  <a
                    class="text-danger"
                    href=""
                    @click.prevent="removeBlock(block.position)"
                    >Odstrániť blok</a
                  >
                </small>
              </template>
            </b-card>

            <b-card v-if="block.block_type === 'text'">
              <vue-markdown :source="block.text" />
              <template v-slot:footer>
                <small>
                  <a href="" @click.prevent="moveBlock(block, -1)"
                    ><font-awesome-icon :icon="['fas', 'arrow-up']"
                  /></a>
                  |
                  <a href="" @click.prevent="moveBlock(block, 1)"
                    ><font-awesome-icon :icon="['fas', 'arrow-down']"
                  /></a>
                  |
                  <b-link
                    :to="{
                      name: 'textblock-change',
                      params: { bookletId: bookletId, blockId: block.id },
                    }"
                    >Upraviť rozprávkový blok</b-link
                  >
                  |
                  <a
                    class="text-danger"
                    href=""
                    @click.prevent="removeBlock(block.position)"
                    >Odstrániť blok</a
                  >
                </small>
              </template>
            </b-card>
          </div>
          <b-card key="last" no-body>
            <template v-slot:footer>
              <small>
                <a
                  target="_blank"
                  :href="
                    $root.backendAdminBaseURL +
                    `booklets/textblock/add/?booklet=${bookletId}&position=${nextBlockPosition}`
                  "
                  >Pridať rozprávkový blok</a
                >
              </small>
              |
              <small>
                <a
                  target="_blank"
                  :href="
                    $root.backendAdminBaseURL +
                    `booklets/problemblock/add/?booklet=${bookletId}&position=${nextBlockPosition}`
                  "
                  >Pridať blok s úlohou</a
                >
              </small>
              <small>
                (po otvorení stránky vyber úlohu alebo vlož rozprávku a blok
                ulož)
              </small>
            </template>
          </b-card>
        </LoadingSpinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { apiBooklets, LoadingSpinner } from "frontend-common";
import VueMarkdown from "vue-markdown";

export default {
  name: "BookletChange",
  components: {
    LoadingSpinner,
    VueMarkdown,
  },
  mixins: [apiBooklets],
  props: {
    bookletId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      solutionsShown: false,
      booklet: {},
    };
  },
  computed: {
    nextBlockPosition() {
      if (!Object.keys(this.booklet).length || this.booklet.blocks.length === 0)
        return 1;
      return this.booklet.blocks[this.booklet.blocks.length - 1].position + 1;
    },
  },
  watch: {},
  mounted() {
    this.reloadContent();
  },
  methods: {
    reloadContent() {
      this.isLoaded = false;
      this.apiBooklet(this.bookletId)
        .then((data) => (this.booklet = data))
        .finally(() => (this.isLoaded = true));
    },
    removeBlock(position) {
      this.$root
        .warningModalConfirm(
          "Naozaj odstrániť blok?",
          "Táto operácia môže byť nenávratná.",
        )
        .then((value) => {
          if (!value) return;

          this.apiUpdateBooklet(this.bookletId, {
            blocks: [{ position }],
          }).then((data) => {
            this.booklet = data;
            this.$root.successToast("Blok bol odstránený.");
          });
        });
    },
    serializeBlock(block) {
      const baseData = {
        block_type: block.block_type,
        position: block.position,
      };
      switch (block.block_type) {
        case "problem":
          return {
            ...baseData,
            problem: block.problem.id,
          };
        case "text":
          return {
            ...baseData,
            text: block.text,
          };
        default:
          return baseData;
      }
    },
    moveBlock(block, diff) {
      const blockIndex = this.booklet.blocks.indexOf(block);
      if (
        blockIndex + diff < 0 ||
        blockIndex + diff > this.booklet.blocks.length
      ) {
        this.$root.warningToast("Blok nie je možné ďalej posúvať.");
        return;
      }
      const switchedBlock = this.booklet.blocks[blockIndex + diff];
      const newPosition = switchedBlock.position;
      switchedBlock.position = block.position;
      block.position = newPosition;

      this.apiUpdateBooklet(this.bookletId, {
        blocks: [
          this.serializeBlock(block),
          this.serializeBlock(switchedBlock),
        ],
      }).then((data) => (this.booklet = data));
    },
  },
};
</script>
